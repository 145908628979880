.gpt3__cta{
    border: 1px solid white;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row;
    padding: 4rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    animation: gradient 2s ease infinite;
    background-size: 200% 200%;
}

.gpt3__cta-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #0E0E0E;
}

.gpt3__cta-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 28px;
    line-height: 45px;
    color: #000000;
}

.gpt3__cta-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.gpt3__cta-btn button{
    background: #000000;
    color: #fff;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    padding: 1rem 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    -webkit-transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    -moz-transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    -ms-transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    -o-transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.gpt3__cta-btn button:hover{
    transform: rotate(-5deg) scale(1.1);
    background-color: #E84118; /* A different shade for hover */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
        padding: 2rem;
    }

    .gpt3__cta-content{
        text-align: center;
    }

    .gpt3__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}