.gpt3__header {
    font-family: var(--font-family);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 6rem;
  }
  
  .gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
  }
  
  .gpt3__header-content h1 {
    font-size: 6rem;
    line-height: 1.2;
    letter-spacing: -0.04em;
    margin-bottom: 1rem;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradient 2s ease infinite;
    background-size: 200% 200%;
    -webkit-animation: gradient 2s ease infinite;
  }
  
  .gpt3__header-content .header-subtext {
    font-size: clamp(1rem, 5vw, 3rem);
    margin-left: 0.5rem; /* Adjust this margin as needed */
  }
  
  .gpt3__header-content p {
    font-size: 2rem;
    line-height: 1.4;
    color: var(--color-text);
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .gpt3__header-content__input {
    width: 100%;
    display: flex;
    gap: 1rem;
    animation: slideInFromLeft 2s ease-out;
    align-items: center; /* Added alignment to center elements vertically */
  }
  
  .gpt3__header-content__input input {
    flex-grow: 2;
    min-height: 50px;
    background: var(--color-footer);
    border-radius: 5px 0 0 5px; /* Adjusted border radius */
    font-size: 1.5rem;
    color: #fff;
    border: none;
    padding: 0.5rem;
    outline: none;
  }
  
  .gpt3__header-content__input button {
    flex-grow: 0.6;
    font-size: 1.5rem;
    min-height: 50px;
    line-height: 2.2rem;
    background: #ff4820;
    border-radius: 0 5px 5px 0; /* Adjusted border radius */
    color: white;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gpt3__header-content__input button:hover {
    transform: rotate(-5deg) scale(1.1);
    background-color: #E84118;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .gpt3__header-content__people {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .gpt3__header-content__people img {
    max-width: 100%;
    height: auto;
    animation: slideInFromRight 2s ease-out;
  }
  
  @media screen and (max-width: 1050px) {
    .gpt3__header {
      flex-direction: column;
      padding: 2rem;
    }
  
    .gpt3__header-content {
      margin: 0;
      text-align: center;
      align-items: center;
    }
  
    .gpt3__header-content__input {
      flex-direction: column;
      gap: 1rem;
    }
  }
  
  @media screen and (max-width: 650px) {
    .gpt3__header h1 {
      font-size: 4rem;
    }
  
    .gpt3__header-content .header-subtext {
      margin-left: 0.3rem; /* Adjust margin for smaller screens */
    }
  
    .gpt3__header p {
      font-size: 1.5rem;
      line-height: 1.8;
    }
  
    .gpt3__header-content__people {
      flex-direction: column;
    }
  
    .gpt3__header-content__people p {
      margin: 0;
    }
  
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
      font-size: 1rem;
      line-height: 1.5rem;
      min-height: 40px;
    }
  }
  
  @media screen and (max-width: 490px) {
    .gpt3__header h1 {
      font-size: 8rem;
    }
  
    .gpt3__header-content .header-subtext {
      margin-left: 0.3rem; /* Further adjust margin for smaller screens */
    }
  
    .gpt3__header p {
      font-size: 1.5rem;
      line-height: 1.2rem;
    }
  
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
      border-radius: 10%;
      font-size: 1rem;
      line-height: 2rem;
      min-height: 40px;
      width: 100%;
    }
  }
  
  .gpt3__header-img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideInFromRight 0.1s ease-out;
    -webkit-animation: slideInFromRight 0.1s ease-out;
  }
  
  .gpt3__header-img img {
    width: 100%;
    height: 100%;
  }
  