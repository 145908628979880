/* .gpt3__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt3__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.gpt3__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.gpt3__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
} */





.gpt3__blog-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer); /* Adjust background color as needed */
    /* Ensure proper box-sizing for inner elements */
    box-sizing: border-box;
    padding: 1rem;
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg); /* Adjust background color as needed */
    /* Ensure proper box-sizing for inner elements */
    box-sizing: border-box;
    overflow: hidden; /* Ensure image does not overflow its container */
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire container */
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem; /* Adjust padding as needed */
    height: 100%; /* Ensure content container takes full height */
}

.gpt3__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 14px; /* Adjust font size */
    font-weight: 400; /* Adjust font weight */
    line-height: 1.5; /* Adjust line height for readability */
    color: #fff; /* Adjust text color */
    margin-bottom: 10px; /* Adjust margin for spacing between paragraphs */
}

.gpt3__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 22px; /* Adjust font size */
    font-weight: 700; /* Adjust font weight */
    line-height: 1.2; /* Adjust line height */
    color: #fff; /* Adjust text color */
    cursor: pointer;
    margin-bottom: 10px; /* Adjust margin for spacing */
}

.gpt3__blog-container_article-content p:last-child {
    cursor: pointer;
    margin-bottom: 0; /* Remove bottom margin for the last paragraph */
}

@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h3 {
        font-size: 18px; /* Adjust font size for smaller screens */
        line-height: 1.3; /* Adjust line height */
    }
}

