* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  /* ie 10+ */
  background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}

.gradient__text {
  background: var(--gradient-text);
  color: text; /* Corrected from background-color */
  -webkit-background-clip: text; /* WebKit browsers */
  background-clip: text; /* Standard property */
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  padding: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes zoomIn {
  from {
      transform: scale(0.5);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes fadeInText {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
      transform: translateX(-100%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
      transform: translateX(100%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
      padding: 4rem;
  }

  .section__margin {
      padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
      padding: 4rem 2rem;
  }

  .section__margin {
      padding: 4rem 2rem;
  }
}
